@use '../../global/_variables.scss' as vars;

$color-bg: #fff;
$gradient1: #9094ff;
$gradient2: #55f4c5;
$gradient3: #a659f3;

.contactTitle {
  font-weight: 600;

  font-size: 20px;
}

.aboutTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 72px;
  line-height: 108px;
  letter-spacing: 0.02em;
  text-align: center;
}

.aboutSubtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 60px;
  letter-spacing: 0.02em;
  padding-bottom: 12px;
}

.contactParagraph {
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  letter-spacing: 0.02em;
  padding-bottom: 12px;
}

.contactText {
  font-size: 18px !important;
}
