@use '../../global/_variables.scss' as vars;

$color-bg: #fff;
$gradient1: #9094ff;
$gradient2: #55f4c5;
$gradient3: #a659f3;

.selectedRow {
  //background-color: #55f4c5;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
    vars.$certupGradient;
}
.selectedRow:hover {
  //background-color: #55f4c5;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
    vars.$certupGradient !important;
}

.unselectedRow:hover {
  background-image: vars.$lightCertupGradient;
  cursor: pointer;
}

.certupButtonColor {
  box-shadow: 0 0 6px 0 $gradient1;
  border: solid 3px transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
    vars.$certupGradient;
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;
  transition: box-shadow 0.2s ease-in-out;
  width: 100%;
  transition: width 1s, height 1s;
}

.aboutTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 72px;
  line-height: 108px;
  letter-spacing: 0.02em;
  text-align: center;
}

.certLabel {
  font-weight: 400;
  font-size: 32px;
  line-height: 48px;
}

.certStatus {
  font-weight: 400;
  font-size: 20px;
  line-height: 177.5%;
  color: #676767;
}

.certupInput {
  background: #f2f2f2;
  border-radius: 38px;
}

.certupInputForm input {
  background: #f2f2f2;
  border-radius: 38px;
}
.certupInputForm input:focus {
  border-color: vars.$certupBtnGradient;
}

.certupInputForm select {
  background-color: #f2f2f2;
  border-radius: 38px;
}
.certupInputForm select:focus {
  border-color: vars.$certupBtnGradient;
}

.inputStyle {
  padding: 6px 12px;
  border: 1px solid #ced4da;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out,
    background-color 0.15s ease-in-out;
  width: 100%;
}
.inputStyle:focus-visible {
  color: #212529;
  background-color: #fff;
  //border-color: #86b7fe;
  border-color: vars.$certupBtnGradient;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgb(13 110 253 / 25%);
  //box-shadow: 0 0 0 0.25rem vars.$certupBtnGradient;
  //box-shadow: 0 0 1px 1px white, inset 0 0 23px 22px white;
  //background: vars.$certupBtnGradient;
}

.invalidDate {
  border-color: #dc3545;
  background-image: url('data:image/svg+xml,%3csvg xmlns=%27http://www.w3.org/2000/svg%27 viewBox=%270 0 12 12%27 width=%2712%27 height=%2712%27 fill=%27none%27 stroke=%27%23dc3545%27%3e%3ccircle cx=%276%27 cy=%276%27 r=%274.5%27/%3e%3cpath stroke-linejoin=%27round%27 d=%27M5.8 3.6h.4L6 6.5z%27/%3e%3ccircle cx=%276%27 cy=%278.2%27 r=%27.6%27 fill=%27%23dc3545%27 stroke=%27none%27/%3e%3c/svg%3e') !important;
  background-repeat: no-repeat !important;
  background-position: right calc(0.375em + 0.1875rem) center !important;
  background-size: calc(0.75em + 0.375rem) calc(0.75em + 0.375rem) !important;
}

.certupInputForm textarea {
  background: #f2f2f2;
  border-radius: 11px;
  min-height: 100px;
}

.largeLabel {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  color: #000000;
}

.backFont {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  color: #666666;
}

.backArrow {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 900;
  font-size: 26px;
  line-height: 24px;
  color: #000000;
  padding-right: 5px;
}

.certupDatePicker {
  background: #f2f2f2;
  border-radius: 38px;
  text-align: left !important;
}

.addBtn {
  border-radius: 11.8356px;
  padding: 0.15rem 1rem;
  box-shadow: 0 0 6px 0 $gradient1;
  border: solid 2px transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
    vars.$certupBtnGradient;
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;

  font-weight: 400;
  font-size: 16px;
  line-height: 33px;
  letter-spacing: 0.02em;
  color: #1f1f1f;
}
.addBtn:hover {
  box-shadow: none;
}
.addBtn:disabled {
  box-shadow: 0 0 6px 0 $gradient1;
}

.sectionTitle {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  /* identical to box height */

  color: #666666;
}

.participantLabels {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  /* identical to box height */

  color: #000000;
  text-align: center;
}

.vr {
  border-left: 2px solid #f2f2f2;
  height: 100%;
  margin: 0px 31.5px;
}

.formHr {
  height: 2px;
  color: #f2f2f2;
  opacity: unset;
}

.previewContainer {
  position: relative;
  text-align: center;
}

/* Centered text */
.previewLoading {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.participantList {
  overflow-y: scroll;
  max-height: 50vh;
}

.fixed_header {
  // width: 400px;
  // table-layout: fixed;
  width: 100%;
  min-width: 100%;
  border-collapse: collapse;
}

.fixed_header tbody {
  display: block;
  overflow-y: auto;
  overflow-x: hidden;
  max-height: 50vh;
  width: 100%;
}

.fixed_header thead tr {
  display: table;
  width: 100%;
  table-layout: fixed;
}

.fixed_header tbody tr {
  display: table;
  width: 100%;
  table-layout: fixed;
  border-width: 0;
}

// .fixed_header thead {
//   background: black;
//   color:#fff;
// }

// .fixed_header th, .fixed_header td {
//   padding: 5px;
//   text-align: left;
//   width: 200px;
// }

// .reviewTable {
//   display: block;
//   overflow-y: auto;    /* Trigger vertical scroll    */
//   overflow-x: hidden;  /* Hide the horizontal scroll */
//   height: 100%;
// }
