.image_picker {
  width: 100%;

  .responsive {
    position: relative;
    margin: 6px 6px;
    float: left;
    opacity: 1;

    .thumbnail {
      border: 1px solid #ddd; /* Gray border */
      border-radius: 4px; /* Rounded border */
      padding: 5px; /* Some padding */
      width: 150px; /* Set a small width */
      opacity: 1;
      margin: 0;

      &:hover {
        box-shadow: 0 0 2px 1px rgba(0, 140, 186, 0.7);
      }
    }

    .selected {
      background-color: rgba(0, 140, 186, 1);
    }

    &:hover {
      cursor: pointer;
    }

    .checked {
      display: none;
    }
  }

  .selected {
    opacity: 0.7;

    .checked {
      display: inline-block;
      transition: 0.5s ease;
      position: absolute;
      bottom: 0;
      right: 0;

      .icon {
        background: url('./check.png');
        background-size: cover;
        height: 75px;
        width: 75px;
      }
    }
  }

  .clear {
    clear: both;
  }
}
