@use '../../../global/_variables.scss' as vars;

$color-bg: #fff;
$gradient1: #9094ff;
$gradient2: #55f4c5;
$gradient3: #a659f3;

.templateBtn {
  border-radius: 11.8356px;
  padding: 0.6rem 2rem;
  box-shadow: 0 0 6px 0 $gradient1;
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;
  font-weight: 400;
  font-size: 16px;
  // line-height: 33px;
  letter-spacing: 0.02em;
  color: #666666;
  height: 100%;
  display: flex;
  flex-direction: column;
  // justify-content: space-between;
}

.selectedBtn {
  border: solid 2px transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
    vars.$certupBtnGradient;
}

.unselectedBtn {
  border: solid 2px #88888850;
}

.imgContainer {
  width: 100%;
  align-self: center;
  flex-grow: 1;
  //display: flex;
  //justify-content: center;
}

.labelContainer {
  width: 100%;
  height: auto;
  align-self: flex-end;
}

.previewImg {
  width: 150px;
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}
