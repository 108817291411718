@use '../../global/_variables.scss' as vars;

$color-bg: #fff;
$gradient1: #9094ff;
$gradient2: #55f4c5;
$gradient3: #a659f3;

.aboutTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 72px;
  line-height: 108px;
  letter-spacing: 0.02em;
  text-align: center;
}

.aboutSubTitle {
  font-style: normal;
  font-weight: 500;
  font-size: 32px;
  //line-height: 108px;
  letter-spacing: 0.02em;
  text-align: center;
}

.payInFont {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 42px;

  color: #000000;
}

.cancelBtn {
  border-radius: 11.8356px;
  padding: 0.3rem 2rem;
  box-shadow: 0 0 6px 0 $gradient1;
  border: solid 2px transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
    vars.$certupBtnGradient;
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;

  font-weight: 400;
  font-size: 16px;
  line-height: 33px;
  letter-spacing: 0.02em;
  color: #666666;
}
.cancelBtn:hover {
  box-shadow: none;
}
.cancelBtn:disabled {
  box-shadow: 0 0 6px 0 $gradient1;
}

.certName {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 33px;
  color: #000000;
}

.accessText {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;

  color: #676767;
}

.certLink {
  word-break: break-all;
}

.bannerContainer {
  background-color: #fafafa;
  padding: 5vh 15vw;
  display: flex;
  flex-direction: column;
}

@media (max-width: 768px) {
  .bannerContainer {
    padding: 5vh 5vw;
  }
}

.bannerTitle {
  font-weight: 400;
  font-size: 28px;
  line-height: 42px;
  color: #353535;
  margin-bottom: 5px;
}

.bannerSubtitle {
  font-weight: 400;
  font-size: 18px;
  //line-height: 177.5%;
  color: #676767;
}

.bannerLink {
  color: #4148ff !important;
}

.certupInputForm input {
  background: #f2f2f2;
  border-radius: 38px;
}
.certupInputForm input:focus {
  border-color: vars.$certupBtnGradient;
}

.bannerCol {
  display: flex;
  flex-direction: column;
}

.socialRow {
  color: #999999;
  font-size: xx-large;
  color: gray;
  cursor: pointer;
  margin-bottom: 3rem;
  display: flex;
  width: auto;
  align-items: center;
}

.socialRow > * {
  margin: 0 0.5rem;
  transition: color 0.2s ease-in-out;
}
.socialRow > * :hover {
  transition: color 0.2s ease-in-out;
  background-color: #31b5c7;
  color: #31b5c7bd;
  background-image: vars.$certupGradient;
  background-size: 1%;
  background-repeat: repeat;
  -webkit-background-clip: text;
  -moz-background-clip: text;
}

.socialRowDisabled {
  color: rgb(78, 78, 78) !important;
  cursor: default;
}

.socialRowDisabled > * :hover {
  color: rgb(78, 78, 78) !important;
  background-color: rgb(78, 78, 78) !important;
  background-image: rgb(78, 78, 78) !important;
  -webkit-background-clip: text;
  -moz-background-clip: text;
}
