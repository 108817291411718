@use '../../global/_variables.scss' as vars;

.blankButton {
  border: none;
  background-color: inherit;
  padding: 0px 3px;
  transition: color 0.2s ease-in-out;
}
.blankButton:hover {
  color: rgba(70, 182, 70, 0.61);
}
.blankButton:active {
  transform: translateY(3px);
}

.clicked {
  color: green !important;
}
