@use '../../global/_variables.scss' as vars;

$color-bg: #fff;
$gradient1: #9094ff;
$gradient2: #55f4c5;
$gradient3: #a659f3;

.certupButtonColor {
  box-shadow: 0 0 6px 0 $gradient1;
  border: solid 3px transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
    vars.$certupGradient;
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;
  transition: box-shadow 0.2s ease-in-out;
  // width: 100%;
  transition: width 1s, height 1s;
}
.certupButtonColor:hover {
  box-shadow: none;
  transition: box-shadow 0.2s ease-in-out;
}
.certupButtonColor:disabled {
  box-shadow: 0 0 6px 0 transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
    vars.$lightCertupGradient;
  //width: fit-content;
  transition: box-shadow 0.2s ease-in-out;

  color: #666666 !important;
}

.defaultBtn {
  border: solid 2px transparent;
  border-radius: 5px;
  padding: 0.2rem 0.4rem;
}

.certupBtn {
  border-radius: 33.5px;
  padding: 0.5rem 3rem;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.02em;
}

.certupBtnSmall {
  font-size: 18px !important;
  padding: 0.25rem 1.5rem;
}

.xsmall {
  border-radius: 11.8356px;
  padding: 0.15rem 1rem;
  font-weight: 400;
  font-size: 16px;
  line-height: 33px;
  letter-spacing: 0.02em;
  color: #1f1f1f;
}
.xsmall :disabled {
  color: #666666 !important;
}

.certupBtnSq {
  border-radius: 11.8356px;
  border: solid 2px transparent;
  padding: 0.3rem 2rem;
  font-weight: 400;
  font-size: 16px;
  line-height: 33px;
  letter-spacing: 0.02em;
  color: #535353;
}

.certupBtnDark {
  color: #fff;
  box-shadow: 2px 1000px 1px #232528 inset;
}

// .btnGradient {
//   background: linear-gradient(var(--color-bg), var(--color-bg)) padding-box,
//     linear-gradient(to right, #9094ff, #55f4c5, #a659f3) border-box;
//   border-radius: 33.5px;
//   border: 2.5px solid transparent;
//   padding: 20px;
// }

// .btnGradient1 {
//   border-width: 4px;
//   border-style: solid;
//   border-image: linear-gradient(to right, darkblue, darkorchid) 1;
// }

// .btnGradient2 {
//   background: linear-gradient(var(--color-bg), var(--color-bg)) padding-box,
//     linear-gradient(to right, darkblue, darkorchid) border-box;
//   border-radius: 50em;
//   border: 4px solid transparent;
// }

// // demo stuff 👇
// .btnGradient1,
// .btnGradient2 {
//   position: relative;
//   display: inline-flex;
//   justify-content: center;
//   align-items: center;
//   font-size: var(--btn-font-size, 1em);
//   color: var(--color-contrast-medium);
//   padding: var(--space-xs) var(--space-md);
//   cursor: pointer;
//   transition: color 0.2s;

//   &:hover {
//     color: var(--color-contrast-high);
//   }
// }
