@use '../../global/_variables.scss' as vars;

$color-bg: #fff;
$gradient1: #9094ff;
$gradient2: #55f4c5;
$gradient3: #a659f3;

.leftBlobs {
  position: absolute;
  z-index: 0;
  left: -180px;
}

.rightBlobs {
  position: absolute;
  z-index: 0;
  //left: -180px;
  left: 95vw;
  top: 500px;
  //display: block;
  //margin-left: auto;
}

.centerContainer {
  display: flex;
  justify-content: center;
}
.centerLines {
  position: absolute;
  z-index: 0;
  //left: -180px;
  text-align: center;
  left: auto;
  top: 60vh;
  right: auto;
}

container {
  z-index: 1;
}

.aboutTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 72px;
  line-height: 108px;
  letter-spacing: 0.02em;
  text-align: center;
}

.aboutSubtitle {
  font-style: normal;
  font-weight: 400;
  font-size: 40px;
  line-height: 60px;
  letter-spacing: 0.02em;
  padding-bottom: 12px;
}

.guideSubtitle {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 24px;
  line-height: 36px;

  /* identical to box height */
  letter-spacing: 0.02em;

  color: #000000;
  text-align: center;
}

.guideStep {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 28px;
  line-height: 42px;

  color: #000000;
}

.keplrSupports {
  font-family: 'Poppins';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;

  /* identical to box height */

  color: #979797;
}

.aboutInfoText {
  font-family: 'Catamaran';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 34px;

  color: #6c6c6c;
}

.infoBox {
  //background: linear-gradient(114.11deg, rgba(116, 235, 213, 0.75) -7.36%, rgba(159, 172, 230, 0.75) 101.34%);
  border: solid 5px transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
    vars.$certupGradient;
  background-origin: border-box;
  background-clip: content-box, border-box;
  border-radius: 50px;
  box-shadow: 2px 1000px 1px #fff inset;
  padding: 45px;
}

.infoTitle {
  font-style: normal;
  font-weight: 600;
  font-size: 56px;
  line-height: 84px;
  letter-spacing: 0.02em;

  text-align: center;
  padding-top: 45px;
  padding-bottom: 70px;
}

.productText {
  font-family: 'Catamaran';
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 30px;
  color: #888a8b;
}
