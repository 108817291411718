@use '../../global/_variables.scss' as vars;

$color-bg: #fff;
$gradient1: #9094ff;
$gradient2: #55f4c5;
$gradient3: #a659f3;

.certupGradient {
  box-shadow: 0 0 6px 0 $gradient1;
  border: solid 3px transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
    vars.$certupBtnGradient;
  box-shadow: 2px 1000px 1px #fff inset;
  background-origin: border-box;
  background-clip: content-box, border-box;
}

.stepButton {
  border-radius: 50%;
  width: 15px;
  height: 15px;
  color: #3b3b3b;
}

.highlight {
  box-shadow: none;
}

.unclickable {
  cursor: default !important;
}

.clickable:hover {
  box-shadow: none;
}
.clickable:disabled {
  box-shadow: 0 0 6px 0 $gradient1;
}

.certupBtn {
  border-radius: 33.5px;
  padding: 0.5rem 3rem;
  box-shadow: 0 0 6px 0 $gradient1;
  border: solid 3px transparent;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
    vars.$certupBtnGradient;
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;

  font-weight: 400;
  font-size: 24px;
  line-height: 36px;
  letter-spacing: 0.02em;
}
.certupBtn:hover {
  box-shadow: none;
}
.certupBtn:disabled {
  box-shadow: 0 0 6px 0 $gradient1;
}

.certupBtnSmall {
  font-size: 18px !important;
  padding: 0.25rem 1.5rem;
}

.btnGradient {
  background: linear-gradient(var(--color-bg), var(--color-bg)) padding-box,
    linear-gradient(to right, #9094ff, #55f4c5, #a659f3) border-box;
  border-radius: 33.5px;
  border: 2.5px solid transparent;
  padding: 20px;
}

.btnTest {
  border: 2.5px solid black;
  border-radius: 33.5px;
  padding: 20px;
}

.btnGradient1 {
  border-width: 4px;
  border-style: solid;
  border-image: linear-gradient(to right, darkblue, darkorchid) 1;
}

.btnGradient2 {
  background: linear-gradient(var(--color-bg), var(--color-bg)) padding-box,
    linear-gradient(to right, darkblue, darkorchid) border-box;
  border-radius: 50em;
  border: 4px solid transparent;
}

// demo stuff 👇
.btnGradient1,
.btnGradient2 {
  position: relative;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  font-size: var(--btn-font-size, 1em);
  color: var(--color-contrast-medium);
  padding: var(--space-xs) var(--space-md);
  cursor: pointer;
  transition: color 0.2s;

  &:hover {
    color: var(--color-contrast-high);
  }
}

.Header {
  font-size: vars.$font-size-h3;
}

.certupButton {
  border-radius: 33.5px;
}
