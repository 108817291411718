.bannerContainer {
  background-color: #fafafa;
  padding: 5vh 15vw;
}

@media (max-width: 768px) {
  .bannerContainer {
    padding: 5vh 5vw;
  }
}

.bannerTitle {
  font-weight: 400;
  font-size: 28px;
  line-height: 42px;
  color: #353535;
  margin-bottom: 5px;
}

.bannerSubtitle {
  font-weight: 400;
  font-size: 20px;
  line-height: 177.5%;
  color: #676767;
  margin-bottom: 30px;
}

.bannerLink {
  color: #4148ff !important;
}
