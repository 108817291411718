$gradient1: #9094ff;
$gradient2: #55f4c5;
$gradient3: #a659f3;
$gradient1-op: #9094ffad;
$gradient2-op: #55f4c5ad;
$gradient3-op: #a659f3ad;
// $certupGradient: linear-gradient(135deg, $gradient1-op, $gradient2-op, $gradient3-op);
$certupBtnGradient: linear-gradient(120deg, $gradient1, $gradient2 65%, $gradient3);

$mainGradient1: rgb(116, 235, 213, 75%);
$mainGradient2: rgb(144, 148, 255, 75%);
$certupGradient: linear-gradient(135deg, $mainGradient1, $mainGradient2);

$lightMainGradient1: rgb(116, 235, 213, 25%);
$lightMainGradient2: rgb(144, 148, 255, 25%);
$lightCertupGradient: linear-gradient(135deg, $lightMainGradient1, $lightMainGradient2);

// General Colors
$body-bg: #0000;
$text-color: #0000;
$link-color: blue;
$link-hover-color: darken($link-color, 15%);
$link-hover-decoration: underline;

// Button Colors

// Typography
$font-size-base: 14px;
$font-size-large: ceil(($font-size-base * 1.25)); // ~18px
$font-size-small: ceil(($font-size-base * 0.85)); // ~12px

$font-size-h1: floor(($font-size-base * 2.6)); // ~36px
$font-size-h2: floor(($font-size-base * 2.15)); // ~30px
$font-size-h3: ceil(($font-size-base * 1.7)); // ~24px
$font-size-h4: ceil(($font-size-base * 1.25)); // ~18px
$font-size-h5: $font-size-base;
$font-size-h6: ceil(($font-size-base * 0.85)); // ~12px

//** Unit-less `line-height` for use in components like buttons.
$line-height-base: 1.428571429; // 20/14

//** Computed "line-height" (`font-size` * `line-height`) for use with `margin`, `padding`, etc.
$line-height-computed: floor(($font-size-base * $line-height-base)); // ~20px

//** By default, this inherits from the `<body>`.
$headings-font-family: inherit;
$headings-font-weight: 500;
$headings-line-height: 1.1;

// Media queries breakpoints

// Extra small screen / phone
$screen-xs: 480px;
$screen-xs-min: $screen-xs;
$screen-phone: $screen-xs-min;

// Small screen / tablet
$screen-sm: 768px;
$screen-sm-min: $screen-sm;
$screen-tablet: $screen-sm-min;

// Medium screen / desktop
$screen-md: 992px;
$screen-md-min: $screen-md;
$screen-desktop: $screen-md-min;

// Large screen / wide desktop
$screen-lg: 1200px;
$screen-lg-min: $screen-lg;
$screen-lg-desktop: $screen-lg-min;

// So media queries don't overlap when required, provide a maximum
$screen-xs-max: ($screen-sm-min - 1);
$screen-sm-max: ($screen-md-min - 1);
$screen-md-max: ($screen-lg-min - 1);
