@use '../../global/_variables.scss' as vars;

.modalButton {
  // height: 100px;
  // width: 250px;
  border: solid 1px transparent !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  padding: 20px 10px !important;
  max-width: 250px;
  width: 100%;
  height: 100%;
  background-color: inherit !important;
  color: inherit !important;
  background-origin: border-box;
  background-clip: content-box, border-box;
  box-shadow: 2px 1000px 1px #fff inset;

  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)),
    vars.$certupGradient;

  transition: box-shadow 0.2s ease-in-out;
}
.modalButton:hover {
  // height: 100px;
  // width: 250px;

  box-shadow: none;
}

$redGradient1: rgba(255, 97, 97, 0.582);
$redGradient2: rgba(153, 31, 0, 0.75);
$redGradient: linear-gradient(135deg, $redGradient1, $redGradient2);

.redOverlay {
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgba(255, 255, 255, 0)), $redGradient;
}
